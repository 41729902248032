import React from 'react'

// Third Party
import styled from 'styled-components'

interface BannerDetailProps {
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Banner
}

const StyledBannerDetail = styled.section``

const BannerDetail: React.FC<BannerDetailProps> = ({ fields }) => (
  <StyledBannerDetail>BannerDetail</StyledBannerDetail>
)

export default BannerDetail
